
import { Vue, Component } from 'vue-property-decorator'
import { types } from 'sass'
import StoryCard from '@/components/entity/StoryCard.vue'
import { Story, StoryDTO } from '@/types/story.types'
import AppContainer from '@/components/AppContainer.vue'
import { storiesTransform } from '@/transform/stories.transform'
import { namespace as userNamespace, UserGetters } from '@/store/user.module'
import AppLoader from '@/components/styled/AppLoader.vue'
import Error = types.Error;

@Component({
  components: { AppLoader, AppContainer, StoryCard },
})
export default class StoriesView extends Vue {
  private storiesDTO: StoryDTO[] = []
  private isLoading = true
  private isError: Error | null = null

  get stories (): Story[] {
    const users = this.$store.getters[`${userNamespace}/${UserGetters.GET_USERS_LIST}`]
    return storiesTransform(this.storiesDTO, users)
  }

  get currentUser () {
    return this.$store.getters[`${userNamespace}/${UserGetters.GET_USER}`]
  }

  async fetchStories () {
    this.isLoading = true
    this.isError = null

    try {
      const { data } = await this.$services.stories.getAll()
      this.storiesDTO = data
    } finally {
      this.isLoading = false
    }
  }

  async mounted () {
    await this.fetchStories()
  }
}
