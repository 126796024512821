import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  sub,
} from 'date-fns'

export interface IDistance {
  years: number,
  months: number,
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
}

export const getDistance = (startDate: Date, rightDate: Date): IDistance => {
  const distance: IDistance = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  let NOW_DATE_WORKABLE = startDate

  distance.years = differenceInYears(NOW_DATE_WORKABLE, rightDate)
  NOW_DATE_WORKABLE = sub(NOW_DATE_WORKABLE, { years: distance.years })

  distance.months = differenceInMonths(NOW_DATE_WORKABLE, rightDate)
  NOW_DATE_WORKABLE = sub(NOW_DATE_WORKABLE, { months: distance.months })

  distance.days = differenceInDays(NOW_DATE_WORKABLE, rightDate)
  NOW_DATE_WORKABLE = sub(NOW_DATE_WORKABLE, { days: distance.days })

  distance.hours = differenceInHours(NOW_DATE_WORKABLE, rightDate)
  NOW_DATE_WORKABLE = sub(NOW_DATE_WORKABLE, { hours: distance.hours })

  distance.minutes = differenceInMinutes(NOW_DATE_WORKABLE, rightDate)
  NOW_DATE_WORKABLE = sub(NOW_DATE_WORKABLE, { minutes: distance.minutes })

  distance.seconds = differenceInSeconds(NOW_DATE_WORKABLE, rightDate)

  return distance
}
