import { Story, StoryDTO } from '@/types/story.types'
import { UserData } from '@/types/global.types'

export const storiesTransform = (stories: StoryDTO[], authors: UserData[]): Story[] => {
  return stories.map((story) => {
    const date = story.attributes.date || story.attributes.createdAt
    const author = authors.find(user => user.id === story.attributes.authorId)?.username || 'неизвестно'

    return {
      id: story.id,
      image: story.attributes.image?.data.attributes,
      text: story.attributes.text,
      author,
      date,
    }
  })
}
