import Vue from 'vue'
import App from '@/App.vue'
import 'normalize.css'
import '@/assets/global.scss'

import router from '@/router'
import store from '@/store'
import services from '@/services'

import { appHeight } from '@/utils/app-height'

import { namespace as userNamespace, UserActions } from '@/store/user.module'
import { vueErrorHandler } from '@/utils/error-handler'

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'development') {
  Vue.config.errorHandler = vueErrorHandler
}

Vue.prototype.$services = services

Vue.prototype.$uploads = (url: string): string => {
  if (process.env.VUE_APP_API_URL === 'http://localhost:1337') {
    return `${process.env.VUE_APP_API_URL}${url}`
  } else {
    return url
  }
}

new Vue({
  router,
  store,
  async mounted () {
    await store.dispatch(`${userNamespace}/${UserActions.INIT_USER}`)
    await store.dispatch(`${userNamespace}/${UserActions.INIT_USERS_LIST}`)
  },
  render: h => h(App),
}).$mount('#app')

window.addEventListener('resize', appHeight)
appHeight()
