
import { Vue, Component, Prop } from 'vue-property-decorator'
import { getDistance, IDistance } from '@/utils/distance'
import {
  ENDING_FORMATTER_DAYS,
  ENDING_FORMATTER_HOURS,
  ENDING_FORMATTER_MINUTES,
  ENDING_FORMATTER_MONTHS,
  ENDING_FORMATTER_SECONDS,
  ENDING_FORMATTER_YEARS,
  numberEndingFormatter,
} from '@/utils/number-ending-formatter'

@Component
export default class DistanceIndicator extends Vue {
  @Prop({ required: true, type: Date })
  readonly startDate!: Date

  private distance: IDistance = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  private interval = 0

  private calculate () {
    this.distance = getDistance(new Date(), this.startDate)
  }

  get outputContent () {
    return {
      [numberEndingFormatter(this.distance.years, ENDING_FORMATTER_YEARS)]: this.distance.years,
      [numberEndingFormatter(this.distance.months, ENDING_FORMATTER_MONTHS)]: this.distance.months,
      [numberEndingFormatter(this.distance.days, ENDING_FORMATTER_DAYS)]: this.distance.days,
      [numberEndingFormatter(this.distance.hours, ENDING_FORMATTER_HOURS)]: this.distance.hours,
      [numberEndingFormatter(this.distance.minutes, ENDING_FORMATTER_MINUTES)]: this.distance.minutes,
      [numberEndingFormatter(this.distance.seconds, ENDING_FORMATTER_SECONDS)]: this.distance.seconds,
    }
  }

  mounted () {
    this.calculate()

    this.interval = setInterval(this.calculate, 1000)
  }

  beforeDestroy () {
    clearInterval(this.interval)
  }
}
