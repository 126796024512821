import axios, { AxiosError, AxiosInstance } from 'axios'
import qs from 'qs'

const jwt = localStorage.getItem('jwt')

const httpClient: AxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  },
})

if (jwt) {
  httpClient.defaults.headers.common.Authorization = `Bearer ${jwt}`
}

httpClient.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, (error: AxiosError) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response?.status === 401) {
    localStorage.removeItem('jwt')
    localStorage.removeItem('userData')
    window.location.reload()
  }
  return Promise.reject(error)
})
export { httpClient }
