
import { Vue, Component } from 'vue-property-decorator'
import WeAnimation from '@/components/styled/WeAnimation.vue'
import AppContainer from '@/components/AppContainer.vue'
import { namespace as userNamespace, UserActions, UserGetters } from '@/store/user.module'

@Component({
  components: { AppContainer, WeAnimation },
})
export default class AppHeader extends Vue {
  private onScroll () {
    document.documentElement.classList.toggle('is-scroll', window.scrollY >= 30)
  }

  mounted () {
    window.addEventListener('scroll', this.onScroll, { passive: true })
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }

  get currentUser () {
    return this.$store.getters[`${userNamespace}/${UserGetters.GET_USER}`]
  }

  onLogout () {
    this.$store.dispatch(`${userNamespace}/${UserActions.RESET_USER}`)
  }

  onLogin () {
    this.$router.push('/login')
  }
}
