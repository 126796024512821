import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import userModule, { namespace as userNamespace } from '@/store/user.module'

Vue.use(Vuex)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

const store = new Store<RootState>({
  strict: true,
  modules: {
    [userNamespace]: userModule,
  },
})

export default store
