/**
 * @param number
 * @param titles ['найдена', 'найдено', 'найдены']
 */
export const numberEndingFormatter = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
}

export const ENDING_FORMATTER_YEARS = ['год', 'года', 'лет']
export const ENDING_FORMATTER_MONTHS = ['месяц', 'месяца', 'месяцев']
export const ENDING_FORMATTER_DAYS = ['день', 'дня', 'дней']
export const ENDING_FORMATTER_HOURS = ['час', 'часа', 'часов']
export const ENDING_FORMATTER_MINUTES = ['минуту', 'минуты', 'минут']
export const ENDING_FORMATTER_SECONDS = ['секунду', 'секунды', 'секунд']
