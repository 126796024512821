import { ApiService } from '@/core/api.service'

export class MediaService extends ApiService {
  async uploadMedia (file: File) {
    const formData = new FormData()
    formData.append('files', file)
    const { data } = await this.httpClient.post('/upload', formData)
    if (Array.isArray(data) && data.length) {
      return data[0]
    }
    return null
  }
}
