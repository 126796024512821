import { ApiService } from '@/core/api.service'
import { UserData } from '@/types/global.types'

export class UserService extends ApiService {
  async login (identifier: string, password: string): Promise<UserData> {
    const { data } = await this.httpClient.post('/auth/local', {
      identifier,
      password,
    })
    const { jwt, user } = data

    localStorage.setItem('jwt', jwt)
    localStorage.setItem('userData', JSON.stringify(user))
    this.httpClient.defaults.headers.common.Authorization = `Bearer ${jwt}`

    return user
  }

  async fetchUsers (): Promise<UserData[]> {
    const { data } = await this.httpClient.get('/users')
    return data
  }
}
