
import { Vue, Component } from 'vue-property-decorator'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import { AxiosError } from 'axios'
import AppContainer from '@/components/AppContainer.vue'
import FileInput from '@/components/elements/FileInput.vue'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'

@Component({
  components: { FileInput, AppContainer, VueDatePicker },
})
export default class CreateStoryView extends Vue {
  private text = ''
  private image: File | null = null
  private date: Date | string = new Date()
  private maxDate = new Date()

  private isLoading = false
  private error = ''

  onFileChange (file: File) {
    this.image = file
  }

  async onSubmit () {
    this.error = ''

    if (this.image === null && this.text === '') {
      this.error = 'заполини все поля дурачок'
    }

    try {
      this.isLoading = true
      const image = await this.$services.media.uploadMedia(this.image)

      await this.$services.stories.createStory({
        image,
        text: this.text,
        date: this.date,
      })

      await this.$router.push('/')
    } catch (e) {
      if (e instanceof AxiosError) {
        this.error = e.response?.data?.error?.message || e.message
      }
    } finally {
      this.isLoading = false
    }
  }
}
