import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { UserData } from '@/types/global.types'
import { RootState } from '@/store/index'
import { httpClient } from '@/core/http-client.core'
import router from '@/router'
import services from '@/services'

type UserState = {
  user: UserData | null,
  usersList: UserData[],
}

const initState = (): UserState => ({
  user: null,
  usersList: [],
})

const namespace = 'user'

const UserActions = {
  RESET_USER: 'RESET_USER',
  INIT_USER: 'INIT_USER',
  INIT_USERS_LIST: 'INIT_USERS_LIST',
}

const UserMutations = {
  SET_USER: 'SET_USER',
  SET_USERS_LIST: 'SET_USER_LIST',
}

const UserGetters = {
  GET_USER: 'GET_USER',
  GET_USERS_LIST: 'GET_USERS_LIST',
}

const state = (): UserState => initState()

const mutations: MutationTree<UserState> = {
  [UserMutations.SET_USER] (state, payload: UserData | null) {
    state.user = payload
  },
  [UserMutations.SET_USERS_LIST] (state, users: UserData[]) {
    state.usersList = users
  },
}

const actions: ActionTree<UserState, RootState> = {
  async [UserActions.RESET_USER] ({ commit }) {
    commit(UserMutations.SET_USER, null)
    localStorage.removeItem('jwt')
    localStorage.removeItem('userData')
    httpClient.defaults.headers.common.Authorization = false

    if (router.currentRoute.name !== 'home') {
      await router.push('/')
    }
  },
  [UserActions.INIT_USER] ({ commit }) {
    const rawData = localStorage.getItem('userData')
    let user: UserData | null = null

    if (rawData !== null) {
      user = JSON.parse(rawData) as UserData
    }

    commit(UserMutations.SET_USER, user)
  },
  async [UserActions.INIT_USERS_LIST] ({ commit }) {
    const users = await services.user.fetchUsers()
    commit(UserMutations.SET_USERS_LIST, users)
  },
}

const getters: GetterTree<UserState, RootState> = {
  [UserGetters.GET_USER]: ({ user }) => {
    return user
  },
  [UserGetters.GET_USERS_LIST]: ({ usersList }) => {
    return usersList
  },
}

const module: Module<UserState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export { namespace, UserActions, UserGetters, UserMutations }

export default module
