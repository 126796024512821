import { ApiService } from '@/core/api.service'
import { StrapiCollection, StrapiImage } from '@/types/global.types'
import { StoryDTO } from '@/types/story.types'

export class StoriesService extends ApiService {
  async getAll (page = 1): Promise<StrapiCollection<StoryDTO[]>> {
    const { data } = await this.httpClient.get('/stories', {
      params: {
        populate: '*',
        sort: ['date:desc', 'createdAt:desc'],
        pagination: {
          page,
          pageSize: 200,
        },
      },
    })

    return data
  }

  async createStory (storyData: { image: StrapiImage, text: string, date: string }): Promise<StoryDTO> {
    const { data } = await this.httpClient.post('/stories', {
      data: storyData,
    })

    return data
  }
}
