import type { AxiosError } from 'axios'

export const vueErrorHandler = (err: AxiosError<{error: Record<string, unknown>}>) => {
  let title = 'Что-то пошло не так'
  let text = err.message

  if (err.isAxiosError) {
    if (err.response.data.error?.message) {
      title = err.response.data.error.message as string
    }
    if (err.response.data && err.response.data.error) {
      text = `${err.config.url}`
      text += ` | ${JSON.stringify(err.response.data.error.details)}`
    }
  }

  alert(`${title}\n${text}`)
}
