import { StoriesService } from '@/services/stories.service'
import { UserService } from '@/services/user.service'
import { MediaService } from '@/services/media.service'
import { httpClient } from '@/core/http-client.core'

export interface IServices {
  stories: StoriesService,
  user: UserService,
  media: MediaService,
}

const services: IServices = {
  stories: new StoriesService(httpClient),
  user: new UserService(httpClient),
  media: new MediaService(httpClient),
}

export default services
