
import { Vue, Component, Prop, Ref, Emit } from 'vue-property-decorator'

@Component
export default class FileInput extends Vue {
  @Ref('fileInputElement')
  private readonly fileInputElement: HTMLInputElement

  @Prop({ required: false, type: String, default: '' })
  readonly accept!: string

  @Emit()
  onFileChange (): File {
    return this.fileInputElement.files[0]
  }
}
