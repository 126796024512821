import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import StoriesView from '@/views/StoriesView.vue'
import AuthView from '@/views/AuthView.vue'
import CreateStoryView from '@/views/CreateStoryView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: StoriesView,
  },
  {
    path: '/login',
    name: 'login',
    component: AuthView,
  },
  {
    path: '/create',
    name: 'create',
    component: CreateStoryView,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
