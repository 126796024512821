
import { Vue, Component } from 'vue-property-decorator'
import DistanceIndicator from '@/components/styled/DistanceIndicator.vue'
import AppContainer from '@/components/AppContainer.vue'

@Component({
  components: { AppContainer, DistanceIndicator },
})
export default class TogetherCounter extends Vue {
}
