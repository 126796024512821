
import { Component, Vue } from 'vue-property-decorator'
import AppHeader from '@/components/AppHeader.vue'
import AppContainer from '@/components/AppContainer.vue'
import TogetherCounter from '@/components/TogetherCounter.vue'
import StoriesView from '@/views/StoriesView.vue'
import AuthView from '@/views/AuthView.vue'

@Component({
  components: { AuthView, StoriesView, TogetherView: TogetherCounter, AppContainer, AppHeader },
})
export default class App extends Vue {
}
