
import { Vue, Component } from 'vue-property-decorator'
import { AxiosError } from 'axios'
import AppContainer from '@/components/AppContainer.vue'
import { namespace as userNamespace, UserMutations } from '@/store/user.module'

@Component({
  components: { AppContainer },
})
export default class AuthView extends Vue {
  private email = ''
  private password = ''
  private error = ''

  async onSubmit () {
    this.error = ''
    try {
      const user = await this.$services.user.login(this.email, this.password)
      this.$store.commit(`${userNamespace}/${UserMutations.SET_USER}`, user)
      await this.$router.push('/')
    } catch (e) {
      if (e instanceof AxiosError) {
        this.error = e.response?.data?.error?.message || e.message
      }
    }
  }
}
