
import { Vue, Component, Prop } from 'vue-property-decorator'
import { format, formatDistance, isAfter, subDays } from 'date-fns'
import { ru } from 'date-fns/locale'
import { Story } from '@/types/story.types'

@Component
export default class StoryCard extends Vue {
  @Prop({ required: true, type: Object })
  readonly story!: Story

  get date () {
    const isDirectDate = isAfter(subDays(new Date(), 30), new Date(this.story.date))
    if (isDirectDate) {
      return format(new Date(this.story.date), 'dd MMM yyyy', { locale: ru })
    } else {
      return formatDistance(
        new Date(this.story.date),
        new Date(),
        { locale: ru, addSuffix: true },
      )
    }
  }
}
